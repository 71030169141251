import React, { Component } from "react"
import styled from "styled-components"
import { withFirebase } from "./firebase"
import { CompanyLogo } from "../../lib/componentHelpers"

const FGPDiv = styled.div`
  margin-top: 0;
  width: 100%;
  height: 100%;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #19232d;
`

const Form = styled.form`
  margin-top: 2em;
  width: 310px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #19232d;
  color: #ffffff;
`

const TItle = styled.div`
  width: 310px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const Span = styled.span`
  display: inline-block;
  font-family: Noe Display;
  font-size: 20px;
  font-weight: bold;
  line-height: 31px;
  text-align: center;
`

const Input = styled.input`
  width: 310px;
  height: 33px;
  font-size: 16px;
  font-family: "overpass";
  margin: 25px 0 25px 0;
  align-self: center;
  border: none;
  border-bottom: 1px solid #262c47;
  background-color: transparent;
  color: #ffffff;
  padding: 5px;
  margin-bottom: 2em;
  outline: none;
  ::placeholder {
    color: #ffffff;
  }
`

const Button = styled.button`
  width: 310px;
  height: 50px;
  background-color: #fbffa0;
  color: #19232d;
  font-size: 14px;
  font-weight: 900;
  border: none;
  text-transform: uppercase;
  padding: 15px;
  align-self: center;
  outline: none;
  &:hover {
    cursor: pointer;
    background-color: #ffffff;
    color: #19232d;
    border: 1px solid #19232d;
  }
`

const BSLogoDiv = styled.div`
  margin-top: 12em;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 560px) {
    display: none;
  }
`

const ErrorSpan = styled.span`
  font-family: Noe Display;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
`

const INITIAL_STATE = {
  email: "",
  error: null
}

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
  }

  handlePasswordReset = event => {
    event.preventDefault()

    const { email } = this.state

    // TODO: Check email validity
    const reg = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/

    if (reg.test(String(email).toLowerCase())) {
      this.props.firebase
        .doPasswordReset(email)
        .then(() => {
          this.setState({ ...INITIAL_STATE })
        })
        .catch(err => {
          if (err.code === "auth/invalid-email")
            this.setState({ error: `Die E-Mail-Adresse ist falsch formatiert.` })

          if (err.code === "auth/user-not-found")
            this.setState({
              error: `Es gibt keinen Benutzerdatensatz, der dieser Kennung entspricht. Der Benutzer wurde möglicherweise gelöscht.`
            })
        })
    } else {
      this.setState({ error: `Die eingegebene E-Mail-Adresse hat kein korrektes Format.` })
    }
  }

  handleInputChange = ({ target }) => {
    this.setState({ [target.name]: target.value })
  }

  render() {
    const { email, error } = this.state

    return (
      <FGPDiv>
        <Form data-form-id="passwordForgetComp" onSubmit={this.handlePasswordReset}>
          {error ? (
            <TItle>
              <ErrorSpan>{error}</ErrorSpan>
            </TItle>
          ) : (
            <TItle>
              <Span>Setze dein Passwort zurück</Span>
            </TItle>
          )}
          <Input
            name="email"
            type="text"
            data-input-id="email"
            placeholder="Email Addresse"
            value={email}
            onChange={this.handleInputChange}
          />
          <Button
            id="passwordForgetSubmitButton"
            data-button-id="passwordForgetSubmitButton"
            type="submit"
          >
            Reset My Password
          </Button>
        </Form>

        <BSLogoDiv>
          <CompanyLogo />
        </BSLogoDiv>
      </FGPDiv>
    )
  }
}

export default withFirebase(PasswordForgetFormBase)
